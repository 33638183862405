import React from 'react';

import SEO from '../components/Layout/SEO';
import ContactSuccessHeader from '../components/ContactPage/ContactSuccessHeader';
import Layout from '../components/Layout/Layout';

const Contact = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='Form Success' />
			<ContactSuccessHeader />
		</Layout>
	);
};

export default Contact;
