import React from 'react';
import styled from '@emotion/styled';

import SectionDark from '../shared/SectionDark';

import respond from '../../styles/mediaQueries';

const StyledHeader = styled('div')` 
	margin-bottom: 16rem;

	${respond('bp4')} {
		margin-bottom: 12rem;
	}
`;

const SuccessMessage = styled('h6')`
    display: block;
    text-align: center;
`;

const ContactSuccessHeader = () => {
	return (
		<StyledHeader>
			<SectionDark>
				<SuccessMessage>
					Thank you! I'll get back to you via email ASAP.
				</SuccessMessage>
			</SectionDark>
		</StyledHeader>
	);
};

export default ContactSuccessHeader;
